@import './colors'

*,
::after,
::before
  box-sizing: border-box
  margin: 0
  padding: 0
  font-family: sans-serif

html
  scroll-behavior: smooth

body
  background-color: var(--background-color)
  color: var(--text-color)
  font-size: 1.2em

p
  margin-top: .175em
  margin-bottom: .25em

a
  color: #{$nord11}

h1, h2
  margin-bottom: 1em

h1, h2, h3, h4, h5, h6
  font-family: 'Mitr', sans-serif
  margin-bottom: .5em

h1
  font-weight: 600

h2
  font-weight: 500

h3, h4, h5, h6
  font-weight: 400
