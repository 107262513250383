footer
  padding: 5em 0
  background-color: var(--background-color-darker)

  h2
    margin-bottom: 1em

  h3
    margin-top: 1em
    margin-bottom: 1em
