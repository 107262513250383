@font-face {
  font-family: Mitr;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Mitr-Regular.b065526c.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Mitr;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Mitr-Medium.a601f8dc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Mitr;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Mitr-SemiBold.83abd12b.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.container {
  width: 740px;
  max-width: calc(100% - 2em);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1440px) {
  .container {
    width: 700px;
  }
}

@media (max-width: 1240px) {
  .container {
    width: 640px;
  }
}

@media (max-width: 940px) {
  .container {
    width: 600px;
  }
}

@media (prefers-color-scheme: light) {
  body {
    --theme-color: #2e3440;
    --background-color: #eceff4;
    --background-color-darker: #d8dee9;
    --header-background-color: #4c566a;
    --text-color: #2e3440;
    --text-secondary-color: #d8dee9;
    --accent-color: #81a1c1;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --theme-color: #2e3440;
    --background-color: #2e3440;
    --background-color-darker: #232831;
    --header-background-color: #232831;
    --text-color: #eceff4;
    --text-secondary-color: #d8dee9;
    --accent-color: #88c0d0;
  }
}

.theme-switch-button svg {
  width: 80px;
  height: 80px;
  display: block;
}

.theme-switch-button svg path {
  fill: var(--color);
}

.tree {
  margin-top: 1.5em;
}

.tree .branch .date {
  font-style: italic;
  font-weight: 500;
}

@media (max-width: 1240px) {
  .tree .branch {
    margin-top: 1em;
  }

  .tree .branch .date {
    border-top: 1px solid var(--background-color-darker);
    padding-top: 1em;
  }
}

@media (min-width: 1240px) {
  .tree .branch {
    width: calc(100% - 1.85em);
    margin-bottom: auto;
    margin-left: auto;
    padding-bottom: 2em;
    position: relative;
  }

  .tree .branch:last-of-type {
    padding-bottom: 0;
  }

  .tree .branch .date {
    width: max-content;
    margin-top: .375em;
    position: absolute;
    top: 0;
    right: calc(100% + 3.25em);
  }

  .tree .branch:before {
    content: " ";
    width: 2px;
    height: calc(100% + .3em);
    background-color: var(--text-secondary-color);
    position: absolute;
    top: 0;
    right: calc(100% + 1.625em);
    transform: translateX(50%);
  }

  .tree .branch:after {
    content: " ";
    width: 1.25em;
    height: 1.25em;
    border: .25em solid var(--accent-color);
    background: var(--background-color);
    border-radius: 50%;
    position: absolute;
    top: 1.2em;
    right: calc(100% + 1em);
    transform: translateY(-50%);
  }
}

footer {
  background-color: var(--background-color-darker);
  padding: 5em 0;
}

footer h2 {
  margin-bottom: 1em;
}

footer h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}

header {
  min-height: 900px;
  background: var(--header-background-color);
  color: #eceff4;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
  display: flex;
}

header > div {
  flex-direction: column;
  display: flex;
}

header > div > * {
  margin-bottom: 1rem;
}

header > div > p {
  margin-top: 1em;
}

header h1 {
  font-size: 4em;
}

header h2 {
  font-size: 2em;
  font-weight: 500;
}

header .presentation {
  background-color: #ffffff30;
  border-radius: .25em;
  padding: 1.5em;
  font-size: 1.2em;
}

@media (max-width: 940px) {
  header h1 {
    font-size: 3.5em;
  }

  header h2 {
    font-size: 1.8em;
  }

  header .presentation {
    width: 100%;
  }
}

section .project {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

section .project .tag {
  color: #eceff4;
  background-color: #434c5e;
  border-radius: .175em;
  margin-top: .25em;
  margin-bottom: .5em;
  padding: .2em .4em;
  font-size: .8em;
  display: inline-block;
}

section .project .tag.tag-language {
  color: #2e3440;
  background-color: #81a1c1;
}

section .project .tag.tag-tool {
  color: #2e3440;
  background-color: #a3be8c;
}

section#stack h3 {
  margin-top: 1.5em;
}

section#stack .stack {
  grid-row-gap: 1em;
  grid-column-gap: .5em;
  grid: none / 1fr 1fr 1fr 1fr;
  margin: auto -1em;
  display: grid;
}

section#stack .stack span {
  border-radius: .25em;
  align-items: end;
  padding: .75em 1em;
  transition: background .3s ease-in-out;
  display: flex;
}

section#stack .stack span:hover {
  background: var(--background-color-darker);
}

section#stack .stack span img {
  height: 1.5em;
  margin-right: .5em;
}

@media (max-width: 1440px) {
  section#stack .stack {
    grid: none / 1fr 1fr 1fr;
  }
}

@media (max-width: 1240px) {
  section#stack .stack {
    grid-column-gap: .5em;
    grid-row-gap: .25em;
    grid: none / 1fr 1fr;
  }
}

@media (max-width: 940px) {
  section#stack .stack {
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid: none / 1fr;
    margin: auto -.5em;
  }

  section#stack .stack span {
    padding: .5em .75em;
  }
}

section {
  margin-top: 6em;
  margin-bottom: 3em;
}

section:last-of-type {
  margin-bottom: 6em;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1.2em;
}

p {
  margin-top: .175em;
  margin-bottom: .25em;
}

a {
  color: #5e81ac;
}

h1, h2 {
  margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5em;
  font-family: Mitr, sans-serif;
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 500;
}

h3, h4, h5, h6 {
  font-weight: 400;
}

.reveal-loaded .reveal [class*="reveal"], .reveal-loaded .reveal[class*="reveal"] {
  opacity: 0;
}

.reveal-loaded .reveal.reveal-ready [class*="reveal-"], .reveal-loaded .reveal.reveal-ready[class*="reveal-"] {
  opacity: 1;
  animation-name: reveal_animation_bottom;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.5, 0, 0, 1);
  animation-fill-mode: both;
}

.reveal-loaded .reveal.reveal-ready .reveal-1, .reveal-loaded .reveal.reveal-ready.reveal-1 {
  animation-delay: .1s;
}

.reveal-loaded .reveal.reveal-ready .reveal-2, .reveal-loaded .reveal.reveal-ready.reveal-2 {
  animation-delay: .2s;
}

.reveal-loaded .reveal.reveal-ready .reveal-3, .reveal-loaded .reveal.reveal-ready.reveal-3 {
  animation-delay: .3s;
}

.reveal-loaded .reveal.reveal-ready .reveal-4, .reveal-loaded .reveal.reveal-ready.reveal-4 {
  animation-delay: .4s;
}

.reveal-loaded .reveal.reveal-ready .reveal-5, .reveal-loaded .reveal.reveal-ready.reveal-5 {
  animation-delay: .5s;
}

.reveal-loaded .reveal.reveal-ready .reveal-6, .reveal-loaded .reveal.reveal-ready.reveal-6 {
  animation-delay: .6s;
}

@media (prefers-reduced-motion: reduce) {
  [class*="reveal-"] {
    animation: none !important;
  }
}

@keyframes reveal_animation_bottom {
  from {
    opacity: 0;
    transform: translateY(4rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*# sourceMappingURL=index.fb3aea5b.css.map */
