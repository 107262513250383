@import '../breakpoints'

.container
  width: 740px
  max-width: calc(100% - 2em)
  margin-left: auto
  margin-right: auto

@media (max-width: $breakpoint-xl)
  .container 
    width: 700px

@media (max-width: $breakpoint-lg)
  .container 
    width: 640px

@media (max-width: $breakpoint-md)
  .container 
    width: 600px

// @media (max-width: $breakpoint-sm)
//   .container 
//     width: 540px
