@import '../../colors'

section .project
  margin-top: 1.5em
  margin-bottom: 1em

  .tag
    display: inline-block
    margin-top: .25em
    margin-bottom: .5em
    padding: .2em .4em
    background-color: #{$nord03}
    color: #{$nord07}
    border-radius: .175em
    font-size: .8em

    &.tag-language
      background-color: #{$nord10}
      color: #{$nord01}
    &.tag-tool
      background-color: #{$nord15}
      color: #{$nord01}
