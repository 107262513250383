@import '../../breakpoints'
@import '../../colors'

header
  align-items: center
  justify-content: center
  display: flex
  min-height: 900px
  margin-bottom: 3em
  
  background: var(--header-background-color)
  color: #{$nord07}

  &>div
    display: flex
    flex-direction: column
    &>*
      margin-bottom: 1rem
    &>p
      margin-top: 1em

  h1
    font-size: 4em

  h2
    font-size: 2em
    font-weight: 500

  .presentation
    padding: 1.5em
    font-size: 1.2em
    background-color: #ffffff30
    border-radius: .25em

@media (max-width: $breakpoint-md)
  header
    h1
      font-size: 3.5em

    h2
      font-size: 1.8em

    .presentation
      width: 100%
